@use "../abstracts/" as *;

.block-text {
  .sub-heading {
    padding: 7px 14px;
    color: rgb(132, 128, 174);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 100px;
    display: inline-block;
    background: #0c153b;

    margin-bottom: 22px;
  }
  .heading {
    margin-bottom: 25px;
    text-transform: none;
  }

  .action-btn {
    span {
      padding: 14px 24px;
    }
  }
}

.pd {
  padding: 0 190px;

  @include tablet {
    padding: 0;
  }
}

.pdb {
  padding: 0 310px;

  @include tablet {
    padding: 0;
  }
}
