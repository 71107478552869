@use "../abstracts/" as *;

@use "../abstracts/" as *;

.action-btn {
  margin-left: 15px;
  position: relative;
  overflow: hidden;
  min-width: 120px;
  height: 45px;
  border-radius: 50px;
  font-weight: 700;
  background: #ffca2b;
  backdrop-filter: blur(4px);
  padding: 5px 15px;
  display: inline-flex;
  border: none;
  align-items: center;
  justify-content: center;
  color: #000;
  &:hover {
    opacity: 0.8;
    color: #000;
  }
}

#scroll-top {
  position: fixed;
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
  text-align: center;
  z-index: 999;
  right: 14px;
  bottom: 23px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  background: #d23247;

  @include flex(center, center);

  span {
    transform: rotate(-180deg);
    display: block;
    font-size: 12px;
  }
}

#scroll-top:hover {
  transform: translateY(-7%);
}
