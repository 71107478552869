@use "../abstracts/" as *;

.page-title {
  position: relative;
  background: url("../../assets/images/background/bg-banner.png") no-repeat;
  padding: 24px 0;

  .shape {
    top: -110%;
    left: -20%;
    width: 800px;
    height: 800px;
  }
  .page-title__body {
    background: linear-gradient(
      89.96deg,
      rgba(255, 255, 255, 0.05) 0.03%,
      rgba(255, 255, 255, 0.008) 49.67%,
      rgba(255, 255, 255, 0.05) 99.96%
    );
    backdrop-filter: blur(2px);

    border-radius: 26px;
    padding: 16px;
  }
  .page-title__main {
    background: linear-gradient(264.28deg, #dec7ff -38.2%, #5c27fe 103.12%),
      linear-gradient(
        89.96deg,
        rgba(255, 255, 255, 0.05) 0.03%,
        rgba(255, 255, 255, 0.008) 49.67%,
        rgba(255, 255, 255, 0.05) 99.96%
      );
    backdrop-filter: blur(2px);

    border-radius: 26px;
    padding: 30px;
    text-align: center;

    .bottom {
      padding-top: 35px;
      margin-top: 30px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
        height: 1px;
        width: 96px;
        background: rgba(255, 255, 255, 0.1);
      }
      p {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 10px;
      }

      .list-social {
        margin-top: 0;
      }
    }
  }

  .breacrumb {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    li {
      margin: 0 16px;
      font-weight: 300;
      font-size: 14px;
      color: #fff;
      font-family: "Readex Pro";
      a {
        font-weight: 300;
        font-size: 14px;
        color: #c2c3c5;
        font-family: "Readex Pro";

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
}
