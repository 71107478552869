@forward "./scss/global";
@forward "./scss/component/index";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

// Fonts
$fonts: "Bai Jamjuree", sans-serif;

// Template Colors

// Primary
$primary: #0d6efd;

// Secondary
$secondary: #6c757d;

// Success
$success: #198766;

// Warning
$warning: #ffd607;

// Info
$info: #0db7f0;

// Danger
$danger: #dc4135;

// White
$white: #ffffff;

// Dark
$dark: #030205;

// Dark Secondary
$dark-sec: #0c153b;

// Heading
$heading: #1f0757;

// Text
$text: #8480ae;

// Text Gray
$text-gray: #c2d4f8;

// Border
$border: #ebebeb;

// Gray
$gray: #efeffd;

// Xtra Large Breakpoint
$breakpoint-xxl: "only screen and (min-width:1400px)";

// Xtra Large Breakpoint
$breakpoint-xl: "only screen and (min-width:1200px)";

// Large Breakpoint
$breakpoint-lg: "only screen and (min-width:992px)";

// Medium Breakpoint
$breakpoint-md: "only screen and (min-width:768px)";

// Small Breakpoint
$breakpoint-sm: "only screen and (min-width:576px)";

// Xtra Small Landscape Breakpoint
$breakpoint-xs: "only screen and (min-width:480px)";

* {
  margin: 0;
  padding: 0;
}

body,
html {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  font-weight: 700;
  letter-spacing: -1px;
}

p {
  font-size: 1rem;
}

a,
a:hover,
a:focus {
  font-weight: 500;
  transition-duration: 500ms;
  text-decoration: none;
  outline: 0 solid transparent;
}

img {
  max-width: 100%;
  height: auto;
}

input:required,
textarea:required {
  box-shadow: none !important;
}

input:invalid,
textarea:invalid {
  box-shadow: none !important;
}

/* Container */

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 320px;
}

@media (min-width: 400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 360px;
  }
}

@media (min-width: 480px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 420px;
  }
}

@media (min-width: 576px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 550px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 940px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1240px;
  }
}

.admin-wrapper {
  .container {
    max-width: 320px;
  }

  @media (min-width: 400px) {
    .container {
      max-width: 360px;
    }
  }

  @media (min-width: 480px) {
    .container {
      max-width: 420px;
    }
  }

  @media (min-width: 576px) {
    .container {
      max-width: 550px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 700px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 690px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 900px;
    }
  }

  @media (min-width: 1400px) {
    .container {
      max-width: 1110px;
    }
  }
}

/* Spacing */

.mt-120 {
  margin-top: 70px;

  //   @media #{$breakpoint-lg} {
  //     margin-top: 120px;
  //   }
}

.mb-120 {
  margin-bottom: 70px;

  //   @media #{$breakpoint-lg} {
  //     margin-bottom: 120px;
  //   }
}

.mt-70 {
  margin-top: 50px;

  //   @media #{$breakpoint-lg} {
  //     margin-top: 70px;
  //   }
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-70 {
  margin-bottom: 50px;

  //   @media #{$breakpoint-lg} {
  //     margin-bottom: 70px;
  //   }
}

.mb-80 {
  margin-bottom: 50px;

  //   @media #{$breakpoint-lg} {
  //     margin-bottom: 80px;
  //   }
}

.mb-100 {
  margin-bottom: 70px;

  //   @media #{$breakpoint-lg} {
  //     margin-bottom: 100px;
  //   }
}

.pt-120 {
  padding-top: 70px;

  //   @media #{$breakpoint-lg} {
  //     padding-top: 120px;
  //   }
}

.pb-120 {
  padding-bottom: 70px;

  //   @media #{$breakpoint-lg} {
  //     padding-bottom: 120px;
  //   }
}

/* Box Shadow */

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(47, 91, 234, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(47, 91, 234, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(47, 91, 234, 0.175) !important;
}

.tooltip {
  font-weight: 500;

  .tooltip-arrow {
    display: none;
  }
}

.tooltip-inner {
  max-width: 240px;
  padding: 0.5rem 0.875rem;
  border-radius: 0.5rem;
}

.divider {
  margin-bottom: 69px !important;
  display: block;
  width: 100%;
  height: 1px;

  @media screen and (min-width: 991px) {
    margin-bottom: 119px !important;
  }
}

.cta-text {
  border-radius: 0.75rem;

  h2 {
    font-size: 1.5rem;
    color: #fff;

    @media screen and (min-width: 601px) {
      font-size: 1.75rem;
    }

    @media screen and (min-width: 991px) {
      font-size: 2rem;
    }

    @media screen and (min-width: 1201px) {
      font-size: 2.5rem;
    }
  }
}

.about-card {
  text-align: center;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #fff;
    border-radius: 0.75rem;

    i {
      font-size: 2rem;
    }

    img {
      max-width: 2.5rem;
    }
  }
}

.counter-wrap {
  border-radius: 0.75rem;
}

.fz-20 {
  font-size: 20px !important;
}

.fz-18 {
  font-size: 18px !important;
}

.fz-16 {
  font-size: 16px !important;
}

.fz-14 {
  font-size: 14px !important;
}

.fz-12 {
  font-size: 12px !important;
}

.z-index-1000 {
  z-index: 1000 !important;
}

.divider {
  margin-bottom: 69px !important;
  display: block;
  width: 100%;
  height: 1px;

  @media #{$breakpoint-lg} {
    margin-bottom: 119px !important;
  }
}

.section-hidden {
  display: none !important;
  visibility: hidden !important;
}

.form-check-input {
  border-width: 2px;
}

.btn {
  font-weight: 500;
  padding: 0.5rem 1rem;
  font-size: 14px;

  @media #{$breakpoint-md} {
    padding: 0.625rem 1.25rem;
  }

  @media #{$breakpoint-lg} {
    padding: 0.875rem 1.75rem;
    font-size: 1rem;
  }
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.375rem 0.75rem;
  font-size: 14px;

  @media #{$breakpoint-md} {
    padding: 0.437rem 0.875rem;
  }

  @media #{$breakpoint-lg} {
    padding: 0.5rem 1.25rem;
  }
}

.btn-link {
  &:hover,
  &:focus {
    box-shadow: none;
  }
}

.btn-minimal {
  padding: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
  background-color: transparent;

  &:hover,
  &:focus {
    box-shadow: none;
    background-color: transparent;
    border-color: transparent;
  }

  &.btn-primary {
    color: $primary;
  }

  &.btn-secondary {
    color: $secondary;
  }

  &.btn-success {
    color: $success;
  }

  &.btn-warning {
    color: $warning;
  }

  &.btn-danger {
    color: $danger;
  }

  &.btn-info {
    color: $info;
  }

  &.btn-dark {
    color: $heading;
  }
}

.hover-primary {
  &:hover {
    color: $primary !important;
  }
}

.hover-secondary {
  &:hover {
    color: $secondary !important;
  }
}

.hover-success {
  &:hover {
    color: $success !important;
  }
}

.hover-warning {
  &:hover {
    color: $warning !important;
  }
}

.hover-danger {
  &:hover {
    color: $danger !important;
  }
}

.hover-info {
  &:hover {
    color: $info !important;
  }
}

.bg-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-overlay {
  position: relative;
  z-index: 1;

  &::after {
    position: absolute;
    content: "";
    background-color: $dark;
    opacity: 0.76;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}

.bg-gray {
  background-color: $gray !important;
}

.bg-text-gray {
  background-color: $text-gray !important;
}

textarea.form-control {
  min-height: calc(8em + 0.75rem + 2px);
}

.section-heading {
  position: relative;
  z-index: 1;
  margin-bottom: 3rem;

  h2 {
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.625rem;
    letter-spacing: -1px;
    line-height: 1.2;

    @media #{$breakpoint-md} {
      font-size: 1.875rem;
    }

    @media #{$breakpoint-lg} {
      font-size: 2.125rem;
    }

    @media #{$breakpoint-xl} {
      font-size: 2.5rem;
    }
  }

  p {
    margin-bottom: 0;

    @media #{$breakpoint-lg} {
      font-size: 20px;
    }
  }

  .spinner-grow {
    flex: 0 0 2rem;
    max-width: 2rem;
  }
}

table {
  min-width: 650px;
}

.card {
  border-color: $border;
  border-radius: 1rem;

  .card-body {
    padding: 1.25rem 1.25rem;
  }
}

.card-img-top {
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}

.border,
.border-start,
.border-end,
.border-bottom,
.border-top {
  border-color: $border !important;
}

.border-dashed {
  border-top: 2px dashed $border !important;
}

#scrollTopButton {
  border: 0 !important;
  position: fixed;
  bottom: 1.25rem;
  right: 1.25rem;
  transition: all 500ms;
  z-index: 9999;
  width: 2rem;
  height: 2rem;
  background-color: $heading;
  font-size: 1.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5rem;
  color: $white;
  cursor: pointer;

  @media #{$breakpoint-lg} {
    bottom: 2rem;
    right: 2rem;
  }
}

.modal-backdrop {
  background-color: $dark;

  &.show {
    opacity: 0.85;
  }
}

.share-modal {
  .modal-dialog {
    max-width: 400px;

    .modal-content {
      background-color: $white;
      border-radius: 0.5rem;
      border: 2px solid $text-gray;

      .modal-body {
        padding: 1.5rem;

        button.btn-close-style {
          padding: 0;
          width: 2rem;
          height: 2rem;
          text-align: center;
          line-height: 2rem;
          border: 0;
          position: absolute;
          top: -1rem;
          right: -1rem;
          z-index: 10;
        }
      }
    }
  }
}

[data-rmiz-wrap="visible"],
[data-rmiz-wrap="hidden"] {
  width: 100%;
}

.notification-list {
  li {
    list-style: none;
    margin-bottom: 0.375rem;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      white-space: normal;
      font-size: 1rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      padding: 0.625rem 1rem;
      background-color: $white;
      border-radius: 0.5rem;

      i {
        display: inline-block;
        flex: 0 0 1.5rem;
        max-width: 1.5rem;
        width: 1.5rem;
        height: 1.5rem;
        background-color: $text-gray;
        border-radius: 0.375rem;
        color: $white;
        line-height: 1.5rem;
        text-align: center;
      }

      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }
}

.fz-20 {
  font-size: 20px !important;
}

.fz-18 {
  font-size: 18px !important;
}

.fz-16 {
  font-size: 16px !important;
}

.fz-14 {
  font-size: 14px !important;
}

.fz-12 {
  font-size: 12px !important;
}

.z-index-1000 {
  z-index: 1000 !important;
}

.divider {
  margin-bottom: 69px !important;
  display: block;
  width: 100%;
  height: 1px;

  @media #{$breakpoint-lg} {
    margin-bottom: 119px !important;
  }
}

.section-hidden {
  display: none !important;
  visibility: hidden !important;
}

.form-check-input {
  border-width: 2px;
}

.btn {
  font-weight: 500;
  padding: 0.5rem 1rem;
  font-size: 14px;

  @media #{$breakpoint-md} {
    padding: 0.625rem 1.25rem;
  }

  @media #{$breakpoint-lg} {
    padding: 0.875rem 1.75rem;
    font-size: 1rem;
  }
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.375rem 0.75rem;
  font-size: 14px;

  @media #{$breakpoint-md} {
    padding: 0.437rem 0.875rem;
  }

  @media #{$breakpoint-lg} {
    padding: 0.5rem 1.25rem;
  }
}

.btn-link {
  &:hover,
  &:focus {
    box-shadow: none;
  }
}

.btn-minimal {
  padding: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
  background-color: transparent;

  &:hover,
  &:focus {
    box-shadow: none;
    background-color: transparent;
    border-color: transparent;
  }

  &.btn-primary {
    color: $primary;
  }

  &.btn-secondary {
    color: $secondary;
  }

  &.btn-success {
    color: $success;
  }

  &.btn-warning {
    color: $warning;
  }

  &.btn-danger {
    color: $danger;
  }

  &.btn-info {
    color: $info;
  }

  &.btn-dark {
    color: $heading;
  }
}

.hover-primary {
  &:hover {
    color: $primary !important;
  }
}

.hover-secondary {
  &:hover {
    color: $secondary !important;
  }
}

.hover-success {
  &:hover {
    color: $success !important;
  }
}

.hover-warning {
  &:hover {
    color: $warning !important;
  }
}

.hover-danger {
  &:hover {
    color: $danger !important;
  }
}

.hover-info {
  &:hover {
    color: $info !important;
  }
}

.bg-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-overlay {
  position: relative;
  z-index: 1;

  &::after {
    position: absolute;
    content: "";
    background-color: $dark;
    opacity: 0.76;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}

.bg-gray {
  background-color: $gray !important;
}

.bg-text-gray {
  background-color: $text-gray !important;
}

textarea.form-control {
  min-height: calc(8em + 0.75rem + 2px);
}

.section-heading {
  position: relative;
  z-index: 1;
  margin-bottom: 3rem;

  h2 {
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.625rem;
    letter-spacing: -1px;
    line-height: 1.2;

    @media #{$breakpoint-md} {
      font-size: 1.875rem;
    }

    @media #{$breakpoint-lg} {
      font-size: 2.125rem;
    }

    @media #{$breakpoint-xl} {
      font-size: 2.5rem;
    }
  }

  p {
    margin-bottom: 0;

    @media #{$breakpoint-lg} {
      font-size: 20px;
    }
  }

  .spinner-grow {
    flex: 0 0 2rem;
    max-width: 2rem;
  }
}

table {
  min-width: 650px;
}

.card {
  border-color: $border;
  border-radius: 1rem;

  .card-body {
    padding: 1.25rem 1.25rem;
  }
}

.card-img-top {
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}

.border,
.border-start,
.border-end,
.border-bottom,
.border-top {
  border-color: $border !important;
}

.border-dashed {
  border-top: 2px dashed $border !important;
}

#scrollTopButton {
  border: 0 !important;
  position: fixed;
  bottom: 1.25rem;
  right: 1.25rem;
  transition: all 500ms;
  z-index: 9999;
  width: 2rem;
  height: 2rem;
  background-color: $heading;
  font-size: 1.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5rem;
  color: $white;
  cursor: pointer;

  @media #{$breakpoint-lg} {
    bottom: 2rem;
    right: 2rem;
  }
}

.modal-backdrop {
  background-color: $dark;

  &.show {
    opacity: 0.85;
  }
}

.share-modal {
  .modal-dialog {
    max-width: 400px;

    .modal-content {
      background-color: $white;
      border-radius: 0.5rem;
      border: 2px solid $text-gray;

      .modal-body {
        padding: 1.5rem;

        button.btn-close-style {
          padding: 0;
          width: 2rem;
          height: 2rem;
          text-align: center;
          line-height: 2rem;
          border: 0;
          position: absolute;
          top: -1rem;
          right: -1rem;
          z-index: 10;
        }
      }
    }
  }
}

[data-rmiz-wrap="visible"],
[data-rmiz-wrap="hidden"] {
  width: 100%;
}

.notification-list {
  li {
    list-style: none;
    margin-bottom: 0.375rem;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      white-space: normal;
      font-size: 1rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      padding: 0.625rem 1rem;
      background-color: $white;
      border-radius: 0.5rem;

      i {
        display: inline-block;
        flex: 0 0 1.5rem;
        max-width: 1.5rem;
        width: 1.5rem;
        height: 1.5rem;
        background-color: $text-gray;
        border-radius: 0.375rem;
        color: $white;
        line-height: 1.5rem;
        text-align: center;
      }

      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }
}

.about-card {
  text-align: center;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    background-color: $heading;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
    text-align: center;
    color: $white;
    border-radius: 0.75rem;

    i {
      font-size: 2rem;
    }

    img {
      max-width: 2.5rem;
    }
  }
}

.counter-wrap {
  border-radius: 0.75rem;
}
